import React from "react"
import { Link } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"

const InfoPage = () => {
  return (
    <Layout>
      <div>
        Gatsby-Link zu einer geschützen Seite:{" "}
        <Link
          className="px-4 font-bold hover:underline"
          to="/veranstaltungen/grossveranstaltungen/eloesungstage/"
        >
          Klick mich
        </Link>
      </div>
    </Layout>
  )
}

export default InfoPage
